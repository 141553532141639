import { createContext, useContext } from 'react';

type Props = React.PropsWithChildren<{ isArteBioPage: boolean }>;

const Ctx = createContext(false);

export const ArteBioPageProvider = ({ children, isArteBioPage }: Props) => {
  return <Ctx.Provider value={isArteBioPage}>{children}</Ctx.Provider>;
};

// indicate if component is rendered in a page served under arte.bio domain
export const useArteBioPage = () => {
  return useContext(Ctx);
};
